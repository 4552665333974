import lozad from 'lozad'

const observer = lozad('.lozad', {
  load: function (el) {
    if (el.tagName === 'IMG') {
      el.src = el.dataset.src
      el.onload = function () {
        el.classList.add('show', 'loaded')
      }
    }
    if (el.tagName === 'DIV') {
      el.style.backgroundImage = `url('${el.dataset.backgroundImage}')`
      el.classList.add('show', 'loaded')
    }
  },
})

observer.observe()
